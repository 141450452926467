<template>
  <!--  修改密码-->
  <div class="modify-safe">
    <el-descriptions title="用户信息" :column="1">
      <el-descriptions-item label="用户名">
        <div>
          {{ name }}
        </div>

      </el-descriptions-item>
      <!--      <el-descriptions-item>-->

      <!--      </el-descriptions-item>-->
    </el-descriptions>

    <el-descriptions>
      <el-descriptions-item label="手机号">
        <el-row type="flex" :gutter="10" class="telephone-row">
          <el-col :span="18">
            {{ telephone }}
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="isVisible=true">
              修改手机号码
            </el-button>
          </el-col>
        </el-row>

      </el-descriptions-item>

    </el-descriptions>

    <el-descriptions>
      <el-descriptions-item label="修改密码">
        <el-form :model="user" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <el-form-item label="新密码" prop="password">
            <el-input v-model="user.password" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item label="再输一次新密码" prop="d_password">
            <el-input v-model="user.d_password" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">修改密码</el-button>
          </el-form-item>
        </el-form>
      </el-descriptions-item>
    </el-descriptions>


    <el-dialog :visible.sync="isVisible" @open="onOpen" @close="onClose" title="修改密码">
      <el-form ref="tel" :model="modify_telephone" :rules="tel_rules" size="medium" label-width="100px"
               label-position="left">
        <el-form-item label-width="0" prop="telephone">
          <el-input v-model="modify_telephone.telephone" placeholder="请输入电话号码" show-word-limit clearable
                    :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label-width="0" prop="code">
          <el-input v-model="modify_telephone.code" placeholder="请输入验证码单行文本" :maxlength="8" clearable
                    :style="{width: '100%'}">
            <template slot="append">
              <div @click="send">
                {{ message }}
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {updatePassword, getInfo, updateTelephone, telephone} from "@/api/user";

export default {
  name: "modify",

  data() {
    return {
      isVisible: false,
      telephone: "",
      send_code: true,
      modify_telephone: {
        telephone: "",
        code: ""
      },
      name: "",
      tel_rules: {
        telephone: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码单行文本',
          trigger: 'blur'
        }],
      },
      message: "获取验证码",
      user: {
        id: this.$store.state.user.id,
        username: this.$store.state.user.name,
        password: "",
        d_password: "",
      },
      total: 60,
      rules: {
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
        ],
        d_password: [
          {required: true, message: '再次新密码', trigger: 'blur'},
        ],
      }
    };
  },
  mounted() {
    getInfo(this.$store.state.user.token).then(result => {
      this.telephone = result.data.user.userTelephone;
      this.name = result.data.user.username;
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    onOpen() {
    },
    onClose() {
      this.isVisible = false;
      // this.$refs['elForm'].resetFields()
    },
    close() {
      this.isVisible = false;
      // this.$emit('update:visible', false)
    },
    // 发送电话号码
    send() {
      // 如果
      let tel = this.modify_telephone.telephone;
      if (this.send_code === true) {
        this.$message("验证码已经发送")
      }
      let that = this;
      if (tel !== undefined && tel !== "") {
        // 发送验证码
        telephone(tel).then(result => {
          this.$message(result.data.msm);
        }).catch(error => {
          this.$message(error.message)
        })
        this.send_code = false;
        // 设置定时器
        let clock = null;
        clock = window.setInterval(() => {
          this.total--;
          console.log("时间在减少")
          // 如果
          if (this.total <= 50) {
            that.message = "获取验证码"
            that.send_code = true;
            window.clearInterval(clock);
            return;
          }
          this.message = this.total + 's后重新发送'
        }, 1000)
      } else {
        this.$message("电话号码为空")
      }
    },
    handelConfirm() {
      this.send_code = false;
      updateTelephone(this.$store.state.user.id, this.modify_telephone.telephone, this.modify_telephone.code).then(result => {
        console.log(result)
        this.$message(result.data.user)
      }).catch(error => {
        this.$message(error.message)
      })
    },
    submitForm() {
      if (this.user.password === this.user.d_password) {
        let that = this;
        updatePassword(this.user).then(result => {
          that.$message({
            type: 'success',
            message: result.data.user
          })
        }).catch(error => {
          that.$message(error.message);
        })
      } else {
        this.$message({
          message: "两次密码不匹配",
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.modify-safe {
  padding-left: 30px;
  padding-top: 30px;
  margin: 0 !important;
}

.telephone-row {
  width: 100%;
}
</style>
